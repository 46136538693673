@import "../../scss/global/variables";

.dialog {
  width: 400px;
  max-width: calc(100vw - 80px);

  .input__error {
    border-bottom: 2px solid $brand-danger-color;
  }
}
