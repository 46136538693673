@import '../../scss/global/common';

.floating {
  position: fixed;
  top: 130px;
  right: 20px;
  z-index: 100000;

  @include tablet {
    top: 200px;
  }
}
