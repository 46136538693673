@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'global/variables';
@import 'fontawesome/brands';
@import 'fontawesome/duotone';
@import 'fontawesome/fontawesome';
@import 'fontawesome/light';
@import 'fontawesome/regular';
@import 'fontawesome/solid';

$theme-colors: (
  'primary': $brand-primary-color,
  'secondary': $brand-secondary-color,
  'tertiary': $brand-tertiary-color,
  'danger': $brand-danger-color,
  'success': $brand-success-color,
  'warning': $brand-warning-color,
  'info': $brand-info-color,
  'light': $brand-light-color,
  'grey': $color-grey-light,
  'grey-light': $color-grey-light-3,
  'dark': $brand-dark-color,
  'white': $color-white,
);

@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: $font-family;
  font-display: swap;
}

html, body {
  font-family: $font-family;
  font-display: swap;
  font-weight: 300;
  min-height: 100%;
  color: $color-dark-grey;
  background-color: $color-white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgb(51, 51, 51) !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $brand-primary-color !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

i {
  font-style: normal;

  &:not(.fa-*) {
    font-style: italic;
  }
}

#root {
  min-height: 100%;
}

hr {
  border-top: none !important;
}

a {
  cursor: pointer;
  color: $brand-primary-color;
  text-decoration: none !important;

  &:hover {
    color: $color-dark-grey;
  }
}

.circle {
  border-radius: 50%;
}

.navbar {
  .dropdown-fullwidth {
    .dropdown {
      position: inherit;
    }

    .dropdown-menu {
      top: 32px;
      max-width: 1320px;
      margin: 1.25rem auto;
      left: 0;
      right: 0;
      padding: 1rem 0;
    }
  }
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: #027d96;
}

.w-18px {
  width: 18px;
}
.w-22px {
  width: 22px;
}
.w-25px {
  width: 25px;
}
.w-50px {
  width: 50px;
}
.h-28px {
  height: 28px;
}
.mw-50px {
  min-width: 50px;
}
.mw-120px {
  min-width: 120px;
}

.h-40px {
  height: 40px;
}

.h-100px {
  height: 100px;
}

.mh-100px {
  max-height: 100px;
}

.h-150px {
  height: 150px;
}

.h-200px {
  height: 200px;
}

.border-radius-none {
  border-radius: 0 !important;
}

.white-space-pre {
  white-space: pre-wrap;
}

.w-15 {
  width: 15%;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}

.pi {
  font-size: 34px;
  line-height: 1.4rem !important;
}

.sticky-top {
  position: fixed;
  left: 0;
  right: 0;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: $brand-primary-color !important;
}
.text-secondary {
  color: $brand-secondary-color !important;
}
.text-tertiary {
  color: $brand-tertiary-color !important;
}
.text-grey {
  color: $color-grey;
}
.text-white {
  color: $color-white;
}

.text-linethrough {
  background: linear-gradient(to left top, transparent 46.75%, currentColor 49.5%, currentColor 50.5%, transparent 53.25%);
}

.primary {
  background-color: $brand-primary-color !important;
}
.secondary {
  background-color: $brand-secondary-color !important;
}
.tertiary {
  background-color: $brand-tertiary-color !important;
}
.grey {
  background-color: $color-grey;
}

.fs-0 {
  font-size: 4rem !important;
}
.fs-7 {
  font-size: 0.9rem !important;
}
.fs-8 {
  font-size: 0.8rem !important;
}
.fs-9 {
  font-size: 0.7rem !important;
  line-height: 1rem !important;
}
.fs-10 {
  font-size: 0.6rem !important;
  line-height: 0.6rem !important;
}

.valign-middle {
  vertical-align: middle;
}

.section-grey {
  background-color: $brand-dark-color;
}
.section-light-grey {
  background-color: #424242;
}

h1 {
  color: $brand-tertiary-color;

  .page_not_found {
    display: block;
    font-size: 120px;
    background: -webkit-linear-gradient($brand-tertiary-color, $brand-secondary-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

h2 {
  color: $brand-primary-color;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

@media (min-width: 1200px) {
  #plans div[class*="styles_plan__"].col-xl-2 {
    width: 20%;
  }
}

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .checkmark {
    position: relative;
    height: 25px;
    width: 25px;
    display: block;
    background-color: #eeeeee;

    &__error {
      border: 1px solid #F44336 !important;
    }

    &:after {
      position: absolute;
      display: none;
      left: 9px;
      top: 4px;
      width: 7px;
      height: 14px;
      content: '';
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.checked {
      background-color: #2196F3;

      &:after {
        display: block;
      }
    }
  }

  &:hover .checkmark {
    background-color: #aaaaaa;
  }
}

input, textarea {
  width: 100%;
  border: none;
  color: white;
  padding: 10px;
  margin-bottom: 5px;
  background-color: transparent;
  border-bottom: 2px solid $brand-secondary-color;
  background-color: rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.input__error {
    border-color: #F44336 !important;
  }
}

::placeholder {
  color: $brand-primary-color;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $brand-primary-color;
}

::-ms-input-placeholder {
  color: $brand-primary-color;
}

button[disabled] {
  border: none !important;
  background: $color-grey !important;
}

.nav {
  --bs-nav-link-color: $brand-primary-color;
  --bs-nav-link-hover-color: $brand-primary-color;
}

.primary-border-bottom {
  border-color: $brand-primary-color !important;
}

.border-bottom-2 {
  border-width: 2px !important;
}

.pagination {
  --bs-pagination-color: #{$brand-primary-color};
  --bs-pagination-bg: #{$brand-tertiary-color};
  --bs-pagination-border-color: #{$brand-secondary-color};

  --bs-pagination-hover-color: #{$brand-tertiary-color};
  --bs-pagination-hover-bg: #{$brand-secondary-color};
  --bs-pagination-hover-border-color: #{$brand-primary-color};

  --bs-pagination-focus-color: #{$color-white};
  --bs-pagination-focus-bg: #{$brand-primary-color};
  --bs-pagination-focus-border-color: #{$brand-secondary-color};

  --bs-pagination-active-color: #{$color-white};
  --bs-pagination-active-bg: #{$brand-primary-color};
  --bs-pagination-active-border-color: #{$brand-primary-color};

  --bs-pagination-disabled-color: #{$color-dark-grey};
  --bs-pagination-disabled-bg: #{$color-grey};
  --bs-pagination-disabled-border-color: #{$brand-primary-color};
}

footer {
  .btn-outline-dark {
    border: 1px solid #222 !important;
  }

  .btn-dark {
    background-color: #111 !important;
    border: 1px solid #111 !important;

    &:hover {
      background-color: #222 !important;
    }
  }
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 700;
}

.card {
  --bs-card-bg: #1B1B1B;
}
.card-body {
  border-radius: 5px;
}

a.card:hover {
  --bs-card-bg: #151515;
}

.pull-right {
  float: right;
}

.link {
  cursor: pointer;
}

.accordion {
  .accordion-button:not(.collapsed) {
    color: $brand-primary-color;
    font-weight: bold;
    border: none;
    background-color: transparent !important;
  }
}

.nav-tabs .nav-link {
  border: none;
  padding: 15px 0;
  margin-right: 30px;
  border-bottom: 1px solid #ccc;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    background: none !important;
    background-color: transparent !important;
  }

  &.active {
    border-bottom: 2px solid $brand-primary-color;
  }

  &:hover {
    border-bottom: 2px solid $brand-secondary-color;
  }
}

.nav-tabs .nav-item {
  &:disabled, button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    background: none !important;
    background-color: transparent !important;
  }
}
