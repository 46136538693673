@import "../../scss/global/variables";

.offCanvas {
  .btn-close {
    color: $color-white;
    filter: brightness(0) invert(1);
  }

  .dropdown-menu {
    background-color: #222;
    border: var(--bs-dropdown-border-width) solid rgba(171, 144, 206, 0.3);

    .dropdown-divider {
      border-top-color: rgba(171, 144, 206, 0.3);
    }

    .dropdown-item {
      color: $color-white;

      &:focus, &:active, &:hover {
        color: $brand-tertiary-color;
        background-color: transparent;
      }
    }
  }
}

.imgHolder {
  position: relative;

  .img {
    width: 80px;
    height: 80px;
    max-width: 80px;
    max-height: 80px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;

    &__normal {
      display: none;
    }

    &:hover {
      cursor: pointer;

      > div {
        display: block;
      }
    }
  }
}

.navbar {
  background: white;
  border-bottom: 2px solid $brand-secondary-color;

  .userImg {
    border-radius: 8px;
    margin-right: 12px;
  }

  .navLinks {
    > div,
    > a {
      color: $color-white !important;
    }
  }

  &.navbar-light {
    &.navbar-expand-lg .navbar-nav .nav-link,
    .navbar-brand {
      color: $color-white;
    }

    .navbar-toggler-icon {
      filter: brightness(0) invert(1);
    }
  }
}

select {
  width: 100%;
  border: none;
  color: white;
  padding: 10px;
  margin-bottom: 5px;
  background-color: transparent;
  border-bottom: 2px solid $brand-tertiary-color;
  background-color: rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.input__error {
    border-color: #F44336 !important;
  }
}
